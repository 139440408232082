@keyframes toBlack {
    from {
        color: black;
        background: white;
    }
    to {
        color: white;
        background: black;
    }
}

.button {
    width: 100%;
    height: 100%;
    border: none;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
}

.button_main {
    animation: toBlack 0.25s ease-in forwards;
}

.button_reverse {
    animation: toWhite 0.25s ease-in forwards;
}

@keyframes toWhite {
    from {
        color: white;
        background: black;
    }
    to {
        color: black;
        background: white;
    }
}

.button_main:hover {
    animation: toWhite 0.25s ease-in forwards;
}

.button_reverse:hover {
    animation: toBlack 0.25s ease-in forwards;
}