@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.arrow{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 48px;
    bottom: 48px;
    width: 56px;
    height: 56px;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
}

.arrow_show{
    opacity: 0;
    animation: fadeIn 0.5s ease-in-out forwards;
}

.arrow_hidden{
    opacity: 1;
    animation: fadeOut 0.5s ease-in-out forwards;
}

.arrow_button{
    width: 48px;
    height: 48px;
    cursor: pointer;
    background: url("../../../assets/arrow-top.png");
    background-size: cover;
}

@media(max-width: 768px)  {
    .arrow{
        position: fixed;
        right: auto;
        left: 20px;
        bottom: 20px;
        width: 40px;
        height: 40px;
    }

    .arrow_button{
        width: 32px;
        height: 32px;
    }
}