@value aquamarine from '../../../style/variables/colors.module.css';

.contact{
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 2fr;
    grid-template-areas:
    "header header"
    "text form";
    row-gap: 40px;
    margin: 80px auto;
}

.contact_header h2{
    grid-area: header;
    display: inline;
}

.contact_text{
    grid-area: text;
}

.contact_form {
    grid-area: form;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    z-index: 99;
    height: calc(100% + 22px);
}

.contact_form__loading{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.contact_form__label{
    display: flex;
    color: white;
}

.contact_form__label > span {
    display: flex;
    gap: 8px;
    margin-right: 12px;
    width: 165px;
}

.contact_form__varName{
    color: aquamarine;
}

.contact_form__element {
    width: 100%;
    padding: 4px 12px;
    background: transparent;
    border: 1px solid white;
    border-radius: 5px;
    color: white;
}

.contact_form__error {
    border: 1px solid orangered;
}

.contact_form__textarea {
    height: 120px;
}

.contact_form__feedback{
    position: absolute;
    bottom: -10px;
    color: white;
}

@media(max-width: 1025px)  {
    .contact {
        grid-template-columns: 100%;
        grid-template-rows: 3fr;
        grid-template-areas:
    "header"
    "text"
    "form";
    }

}

@media(max-width: 480px)  {
    .contact > div:first-of-type {
        text-align: center;
    }
    .contact_text{
        text-align: center;
    }

    .contact_form {
        gap: 12px;
    }

    .contact_form__label {
        flex-direction: column;
    }
}
