@value blue, purple, aquamarine from '../../../style/variables/colors.module.css';

.header {
    display: flex;
    align-content: center;
    justify-content: space-between;
    height: 100px;
    margin-top: 2rem;
}

.button_container__background {
    height: 45px;
    width: 152px;
    background: linear-gradient(to right, aquamarine, blue 53%, purple);
    padding: 1px;
}


@media(max-width: 480px)  {
    .button_container {
        display: flex;
        justify-content: flex-end;
        width: 60%;
    }
}