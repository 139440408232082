@value aquamarine from '../../../style/variables/colors.module.css';
@value small_desktop from '../../../style/variables/breakpoints.module.css';

.hero {
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
}

.hero_containerLeft {
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 80px;
    padding-right: 20px;
}


.hero_containerLeft__mernImg {
    margin-top: 16px;
    width: 300px;
    filter: grayscale(1);
}


.hero_containerLeft__button > div {
    margin-top: 16px;
    display: flex;
}

.hero_containerRight {
    margin-top: 80px;
    padding-right: 12px;
    border-right: 3px solid aquamarine;
    width: 55%;
}

.hero_containerRight img {
    filter: grayscale(1);
    width: 100%;
    border-top-left-radius: 40px;
    border-bottom-right-radius: 40px;
}

@media(max-width: 1025px)  {
    .hero {
        margin-top: 40px;
    }

    .hero_containerLeft{
        margin-top: 40px;
    }

    .hero_containerRight {
        margin-top: 40px;
    }
}

@media(max-width: 768px)  {
    .hero {
        flex-direction: column;
        margin-top: 0;
        text-align: center;
    }

    .hero_containerLeft{
        width: 100%;
        margin-top: 0;
    }

    .hero_containerRight {
        width: 100%;
    }

    .hero_containerLeft__button{
        width: 100%;
        display: flex;
        justify-content: center;
    }
}