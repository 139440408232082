
@value blue, purple, aquamarine from './variables/colors.module.css';

*, *:before, *:after {
    box-sizing: border-box;
    font-size: 16.5px;
}

*::selection {
    background: purple;
    color: black;
}

* {
    scrollbar-color: purple blue;
}

*::-webkit-scrollbar {
    width: 11px;
}

*::-webkit-scrollbar-track {
    background: purple;
    border: 1px solid blue;
    border-radius: 5px;
    margin-left: 10px;
}

*::-webkit-scrollbar-thumb {
    background: aquamarine;
    border: 1px solid blue;
    border-radius: 5px;
}

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    scroll-behavior: smooth;
}

body{
    font-family: 'PT Sans', sans-serif;
    background: black;
    color: white;
    font-size: 14px;
    line-height: 20px;
}

input, textarea{
    font-family: 'PT Sans', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', sans-serif;
}

ol, ul {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
}

a{
    text-decoration: none;
}
main {
    height: 100%;
    position: relative;
}
