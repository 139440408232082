.myWork {
    margin-top: 80px;
}

.myWork_project {
    padding: 24px 0;
}

@media(max-width: 768px)  {
    .myWork{
        text-align: center;
    }
}
