.link {
    background: transparent;
    border: none;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
}

.link_underline {
    width: 100%;
    height: 2px;
}

.link_underline__gradient {
    background: linear-gradient(to right, #2adbb1, #2385ff 53%, #a854ff);
}

.link_underline__white {
    background: white;
}