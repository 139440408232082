@value aquamarine from '../../../../style/variables/colors.module.css';

.project {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 20% 65% 15%;
    grid-template-areas:
    "title image"
    "description image"
    "tags image";
    position: relative;
    opacity: 0;
    left: -15px;
    width: 100%;
    min-height: 300px;
    margin-top: 40px;
    padding: 40px 24px;
    border: 1px solid white;
    border-radius: 20px;
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        left: -15px;
    }
    to {
        opacity: 1;
        left: 0;
    }
}

.project_visible {
    animation: fadeInLeft 1.2s ease-in-out forwards;
}

.project_disActive {
    background: black;
    opacity: 0.5;
    position: absolute;
    width: 100%;
    height: 100%;
}

.project_var {
    color: aquamarine;
}

.project_title {
    grid-area: title;
    margin-bottom: 20px;
}

.project_title__date {
    margin-left: 12px;
    color: aquamarine;
}

.project_description {
    grid-area: description;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-right: 24px;
    align-items: flex-start;
}

.project_description_buttons {
    display: flex;
    gap: 16px;
}

.project_tags {
    grid-area: tags;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    align-content: flex-end;
    gap: 8px;
}

.project_tags__tag {
    padding: 4px 16px;
    border-radius: 40px;
    border: 1px solid aquamarine;
}

.project_img {
    grid-area: image;
    justify-self: self-end;
    width: calc(100% - 40px);
}

@media(max-width: 1025px)  {
    .project {
        grid-template-columns: 60% 40%;
        grid-template-rows: 3fr;
        grid-template-areas:
        "image image"
        "title title"
        "description tags";
    }
    .project_img {
        width: 100%;
        margin-bottom: 40px;
    }

}

@media(max-width: 768px)  {
    .project {
        grid-template-columns: 1fr;
        grid-template-rows: 3fr;
        grid-template-areas:
        "image"
        "title"
        "description"
        "tags";
        padding: 20px 12px;
    }

    .project_title {
        text-align: center;
    }

    .project_description {
        gap: 28px;
        padding-right: 0;
        text-align: center;
    }

    .project_description_buttons {
        justify-content: center;
        width: 100%;
    }

    .project_title__date {
        display: block;
        padding-top: 8px;
    }

    .project_tags {
        justify-content: center;
        width: 100%;
        margin-top: 32px;
    }
}