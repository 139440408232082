.text_paragraph__l {
    font-size: 1.3rem;
    line-height: 1.5;
}

.text_paragraph__m {
    font-size: 1.05rem;
    line-height: 1.5;
}

.text_paragraph__s {
    font-size: .875rem;
    line-height: 1.5;
}

.text_header__l {
    font-size: 3.55rem;
    font-weight: 400;
    line-height: 1.1;
}

.text_header__m {
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1.2;
}

.text_header__underline {
    display: inline;
    padding-bottom: 4px;
    border-bottom: 3px solid #2385ff;
}

.text_header__s {
    font-size: 1.7rem;
    font-weight: 400;
    line-height: 1.2;
}


.gradient {
    -webkit-text-fill-color: transparent;
    background-color: #a854ff;
    background-image: linear-gradient(to right, #2adbb1, #2385ff 53%, #a854ff);
    -webkit-background-clip: text;
}

.section {
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
}

@media(max-width: 1025px)  {
    .text_paragraph__l {
        font-size: 1.15rem;
    }

    .text_paragraph__m {
        font-size: 0.95rem;
    }

    .text_paragraph__s {
        font-size: .8rem;
    }

    .text_header__l {
        font-size: 3.2rem;
    }

    .text_header__m {
        font-size: 2.25rem;
    }

    .text_header__s {
        font-size: 1.5rem;
    }
}

@media(max-width: 768px)  {
    .text_header__l {
        font-size: 2.25rem;
    }
}