.footer{
    display: flex;
    justify-content: space-between;
    gap: 8px;
    border-top: 1px solid gray;
    padding-top:40px;
    margin-bottom: 40px;
}

.footer_buttons{
    display: flex;
    gap: 12px;
}